import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addCategory } from "../../../actions/category.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const AddCategory = ({ addCategory, setAlert }) => {
  const [addModals, setShow] = useState(false);
  const [adv, setAdv] = useState({
    title: "",
    description: "",
    url: "",
    sortBy : "",
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, description ,url ,sortBy} = adv;

  

 
  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || url ==="") {
      setAlert("ارجو تعبئة كل الحقول ", "danger");
    } else {
      addCategory( title, description,url ,sortBy);
      setAdv({
        title: "",
        description: "",
        url :"",
        sortBy :""
      });
      setAlert("تمت إضافة القسم بنجاح", "success");
      setShow(false);
    }
  };

  return (
    <Fragment>
      <a
        href="#!"
        onClick={handleShow}
        className=" btn-outline btn-block btn-radius lead"
      >
        {" "}
        <FontAwesomeIcon icon={faPlus} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إضافة قسم </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
          
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان القسم"
              required
            />
            <FormInput
              name="sortBy"
              type="number"
              handleChange={onChange}
              value={sortBy}
              label="الترتيب"
              required
            />
             <FormInput
              name="url"
              type="text"
              handleChange={onChange}
              value={url}
              label="رمز القسم باللغة الانجليزية"
              required
            />
            <label htmlFor="firstName" className="active">
              عن القسم
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdv({ ...adv, description: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  addCategory,
  setAlert,
})(AddCategory);
