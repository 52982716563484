export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const REMOVE_PROGRESS = "REMOVE_PROGRESS";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const GET_ADMINS = "GET_ADMINS";
export const USER_DELETE = "USER_DELETE";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ERROR_CATEGORY = "ERROR_CATEGORY";

export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const GET_SUBCATEGORY = "GET_SUBCATEGORY";
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const ERROR_SUBCATEGORY = "ERROR_SUBCATEGORY";

export const GET_TYPES = "GET_TYPES";
export const GET_TYPE = "GET_TYPE";
export const ADD_TYPE = "ADD_TYPE";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const DELETE_TYPE = "DELETE_TYPE";
export const ERROR_TYPE = "ERROR_TYPE";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ERROR_PRODUCT = "ERROR_PRODUCT";

