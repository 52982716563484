import { REMOVE_PROGRESS, UPLOAD_PROGRESS } from "../actions/types";

const initialState = {
  loaded: 0,
  total: 0,
  percent: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_PROGRESS:
      return {
        loaded: payload.loaded,
        total: payload.total,
        percent: payload.percent,
      };
    case REMOVE_PROGRESS:
      return {
        loaded: 0,
        total: 0,
        percent: 0,
      };
    default:
      return state;
  }
}
