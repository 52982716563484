import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DeleteAdv from "../../components/modals/products/DeleteProducts";
import EditAdvs from "../../components/modals/products/EditProduct";
import AddImages from "../../components/modals/products/AddImages";
import DeleteImages from "../../components/modals/products/deleteImages"

const AdvsItem = ({ product, isAuthenticated, user,subCategories }) => {
  const { _id, imagegalary, title, discription, updatedAt } = product;

  return (
    <div className="col s12 " style={{ direction: "rtl" }}>
      <div className="card grid-2">
        <div className="card-image">
          {imagegalary&& imagegalary.length > 0 && <img src={imagegalary[0]} alt={title} />}
          
          <br />
          {isAuthenticated && user && user.rolls === 2 && (
            <Fragment>
              <EditAdvs correntProduct={product} subCategories={subCategories} />
              <DeleteAdv id={_id} />
              <DeleteImages  id={_id}/>
              <AddImages id={_id} />
            </Fragment>
          )}

          {/* <Details product={product} /> */}
        </div>
        <div className="card-stacked">
          <div className="card-content right-align">
            <h1>
              {" "}
              <u> {title}</u>
            </h1>
            <h3>{updatedAt}</h3>
            <p dangerouslySetInnerHTML={{ __html: discription }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

AdvsItem.propTypes = {
  adv: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(AdvsItem);
