import {
  GET_SUBCATEGORIES,
  GET_SUBCATEGORY,
  ADD_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  ERROR_SUBCATEGORY,
  DELETE_SUBCATEGORY,
} from "../actions/types";

const inialState = {
  subCategories: [],
  subCategory: null,
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBCATEGORIES:
      return {
        ...state,
        subCategories: payload,
        loading: false,
      };
    case GET_SUBCATEGORY:
      return {
        ...state,
        subCategory: payload,
        loading: false,
      };
    case ADD_SUBCATEGORY:
      return {
        ...state,
        subCategories: [...state.subCategories, payload],
        loading: false,
      };
    case UPDATE_SUBCATEGORY:
      return {
        ...state,
        subCategories: state.subCategories.map((subCategory) =>
          subCategory._id === payload._id ? payload : subCategory
        ),
      };
    case DELETE_SUBCATEGORY:
      return {
        ...state,
        subCategories: state.subCategories.filter((subCategory) => subCategory._id !== payload),
      };
    case ERROR_SUBCATEGORY:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
