import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addProducts,addProductsWithoutImage } from "../../../actions/product.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const AddProduct = ({ addProducts,addProductsWithoutImage, setAlert , subCategories , types }) => {
  const [addModals, setShow] = useState(false);
  const [product, setProduct] = useState({
    title: "",
    description: "",
    subCategory : "",
    type : ""
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, description,subCategory ,type } = product;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };
  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(subCategory)
    if (title === "" || description === "" ||type==="") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا المنتج ", "danger");
    } else {
      if(file === "") {
        addProductsWithoutImage(title, description,subCategory ,type)
      } else {
        addProducts(file, title, description,subCategory ,type);
      }
      
      
      setProduct({
        title: "",
        description: "",
        subCategory:"",
        type :""
      });
      setAlert("تم اضافة المنتج بنجاح", "success");
      setShow(false);
    }
  };

  return (
    <Fragment>
      <a
        href="#!"
        onClick={handleShow}
        className=" btn-outline btn-block btn-radius lead"
      >
        {" "}
        <FontAwesomeIcon icon={faPlus} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إضافة منتج </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <label htmlFor="file"> ادخل صور للمنتج </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple />
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="اسم المنتج"
              required
            />
             <label for="subcategory">اختر القسم الفرعي</label>
            <select
              type="text"
              name="subCategory"
              value={subCategory}
              onChange={onChange}
              label="القسم"
              required
              id="subcategory"
            >
              <option value="">الاقسام ...</option>
              {subCategories&& subCategories.map(subCategory => 
              <option value={subCategory._id}  > {subCategory.title}</option> )
              }
            </select>
             <br />
            {subCategory !== "" && 
            <>
            <label for="type">إختر الصنف للمنتج</label>
            <select
              type="text"
              name="type"
              value={type}
              onChange={onChange}
              label="النوع"
              required
              id="type"
            >
              <option value="">الاصناف</option>
              {types && types.map(type => 
              <>

              {(type.subCategory.id === subCategory )? <option value={type._id}  > {type.title}</option> : null 
              }
              </>
             )
              }
               </select>
              </>
            }
            <label htmlFor="firstName" className="active">
              عن المنتج
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProduct({ ...product, description: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  addProducts,
  setAlert,
  addProductsWithoutImage
})(AddProduct);
