import AdminsItem from './AdminsItem';
import React , {useEffect} from 'react';
import {connect} from "react-redux";
import {getAdmins} from "../../actions/auth"

const ReviewersPage = ({admins,getAdmins}) => {
    useEffect(() => {
       getAdmins()
    }, [])
    return (
     
       <div className="row  container">
      <div className="card">
       <div className="col s12 container center-align  ">
                  <h1 > مسؤولي النظام</h1>
                    <table className="striped ">
                      <thead>
                        <tr>
                          <th className="center-align">ر.م</th>
                          <th className="center-align">الاسم </th>
                          <th className="center-align">البريد الالكتروني</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          { admins.map((admin , i) =>
                                  <AdminsItem admin={admin} i={i+1} key={admin._id} />
                              )}

                      </tbody>
                    </table>
                    </div>
                    </div>
                    </div>
     
    )
}
const mapStateToProps = state =>({
    admins : state.auth.admins,
})

export default connect(mapStateToProps , {getAdmins})(ReviewersPage)
