import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateProduct } from "../../../actions/product.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const UpdateProduct = ({ updateProduct, correntProduct, setAlert,subCategories }) => {
  const [addModals, setShow] = useState(false);

  const [news, setNews] = useState({
    id: correntProduct._id,
    title: correntProduct.title,
    description: correntProduct.description,
    subCategory: correntProduct.subCategory,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, description ,subCategory} = news;

  const onChange = (e) => {
    setNews({ ...news, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateProduct(news);
    setAlert("تم التعديل على المنتج بنجاح", "success");
    setShow(false);
  };

  return (
    <Fragment>
      <a onClick={handleShow}>
        {" "}
        <FontAwesomeIcon icon={faEdit} />
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> التعديل على المنتج </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="اسم المنتج "
              required
            />
             <label for="dep">اختر القسم الفرعي</label>
            <select
              type="text"
              name="subCategory"
              value={subCategory}
              onChange={onChange}
              label="القسم"
              required
              id="dep"
            >
              <option value="">{subCategory.title}</option>
              {subCategories&& subCategories.map(subCategory => 
              <option value={subCategory._id}  > {subCategory.title}</option> )
              }
              
             
            </select>

            <label htmlFor="firstName" className="active">
              عن المنتج
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setNews({ ...news, description: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            تعديل{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  updateProduct,
  setAlert,
})(UpdateProduct);
