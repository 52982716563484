import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateTypes } from "../../../actions/Types.action";
import { setAlert } from "../../../actions/alert";
import "../bootstrap.css";

const UpdateType = ({ updateTypes, correntType, setAlert }) => {
  const [addModals, setShow] = useState(false);

  const [adv, setAdv] = useState({
    id: correntType._id,
    title: correntType.title,
    description: correntType.description,
    url: correntType.url,
    sortBy :  correntType.sortBy,
    subCategory : correntType.subCategory

  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, description,url,subCategory,sortBy } = adv;

  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateTypes(adv);
    setAlert("تم التعديل علي بيانات القسم", "success");
    setShow(false);
  };

  return (
    <Fragment>
      <a onClick={handleShow} className="text-dark">
        {" "}
        <FontAwesomeIcon icon={faPen} />
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> التعديل على الصنف </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الصنف"
              required
            />
             <FormInput
              name="sortBy"
              type="number"
              handleChange={onChange}
              value={sortBy}
              label="الترتيب"
              required
            />
             <FormInput
              name="url"
              type="text"
              handleChange={onChange}
              value={url}
              label="رمز الصنف"
              required
            />
            <label htmlFor="firstName" className="active">
              عن القسم
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdv({ ...adv, description: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  updateTypes,
  setAlert,
})(UpdateType);
