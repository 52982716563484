import {
  GET_TYPES,
  ADD_TYPE,
  UPDATE_TYPE,
  DELETE_TYPE,
  ERROR_TYPE,
  GET_TYPE,
} from "../actions/types";

const inialState = {
  types: [],
  oneType: null,
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TYPES:
      return {
        ...state,
        types: payload,
        loading: false,
      };
    case GET_TYPE:
      return {
        ...state,
        oneType: payload,
        loading: false,
      };
    case ADD_TYPE:
      return {
        ...state,
        types: [...state.types, payload],
        loading: false,
      };
    case UPDATE_TYPE:
      return {
        ...state,
        types: state.types.map((type) =>
          type._id === payload._id ? payload : type
        ),
      };
    case DELETE_TYPE:
      return {
        ...state,
        types: state.types.filter((type) => type._id !== payload),
      };
    case ERROR_TYPE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
