import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";

const Alert = ({ progress }) =>
  progress &&
  progress.percent > 0 && (
    <ProgressBar now={progress.percent} label={`${progress.percent}%`} />
  );

Alert.propTypes = {
  progress: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  progress: state.progress,
});

export default connect(mapStateToProps)(Alert);
