import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import { getCategory } from "../../actions/category.action";
import { getSubCategories } from "../../actions/subCategory.action";
import { getTypes } from "../../actions/Types.action";
import AddSubCategory from "../../components/modals/subCategories/AddSubCategory";
import SubAdvsItems from "./subCategoryItems";

import Spinner from "../../components/Layout/Spinner";
const CategoryPage = ({
  getCategory,
  getSubCategories,
  getTypes,
  types:{types},
  categories: { categories, loading },
  subCategories : {subCategories},
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getCategory();
    getSubCategories()
    getTypes()
    // eslint-disable-next-line
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        <div className="card text-center">
          <h1> الاقسام الفرعية</h1>
        </div>
        {isAuthenticated && user  && (
          <div className="card">
            <AddSubCategory categories={categories} />
          </div>
        )}
        
        <div className="card text-center">
        <table className="striped container-fluid ">
            <thead>
              <tr>
                <th className="right-align"> عنوان القسم الفرعي</th>
                <th className="right-align"> القسم</th>
                <th className="right-align"> رمز القسم الفرعي</th>
                <th className="right-align">عن القسم الفرعي</th>
                <th className="right-align">الاصناف   </th>

              <th></th>
              </tr>
            </thead>
            <tbody>
            {types && subCategories.map((subcategory) => (
              <Fragment key={subcategory._id}>
                 <SubAdvsItems adv={subcategory} categories={categories} types={types.filter(type => type.subCategory && type.subCategory.id ===subcategory._id )} />
              </Fragment>
             ))}
            </tbody>
          </table>
          </div>
          </div>
     
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  subCategories: state.subCategories,
  types: state.types,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getCategory,getSubCategories,getTypes })(CategoryPage);
