import {
  GET_CATEGORIES,
  GET_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  ERROR_CATEGORY,
  DELETE_CATEGORY,
} from "../actions/types";

const inialState = {
  categories: [],
  category: null,
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload],
        loading: false,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category._id === payload._id ? payload : category
        ),
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((category) => category._id !== payload),
      };
    case ERROR_CATEGORY:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
