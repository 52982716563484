import React from 'react'

import {connect } from "react-redux"
import { deleteAccount } from "../../actions/auth";

const ReviwersItem = ({admin ,i ,deleteAccount}) => {
    const {_id,name,email} = admin

    const onSubmit =()=>{
        console.log(_id)
        deleteAccount(_id)
    }
    return (
        <>
        <tr>
            <td className="center-align">{i}</td>
            <td className="center-align">{name}</td>
            <td className="center-align">{email}</td>
            <td className="center-align">
                <button className="btn btn-danger" onClick={onSubmit}>حذف
                </button>
            </td>
                          </tr>
        </>
    )
}

export default connect(null , {deleteAccount})(ReviwersItem)
