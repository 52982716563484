import React, { Fragment } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import DeleteCategory from "../../components/modals/Categories/DeleteCategory";
import EditCategory from "../../components/modals/Categories/EditCategory";

const AdvsItem = ({ adv, isAuthenticated, user }) => {
  const { _id, title, description, url } = adv;

  return (
    <tr>
      <td className="right-align">{title}</td>
      <td className="right-align">{url}</td>
      <td className="center-align" dangerouslySetInnerHTML={{__html :description }} ></td>
      <td className="right-align">{isAuthenticated && user  && (
            <Fragment>
              <EditCategory correntAdv={adv} />
              <DeleteCategory id={_id} />
            </Fragment>
          )}</td>
    </tr>

  );
};

AdvsItem.propTypes = {
  adv: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AdvsItem);
