import axios from "axios";
import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  ERROR_CATEGORY,
  
} from "./types";
import {Url} from "./config"

// GET CONFERANCES
export const getCategory = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/category`);
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CATEGORY,
      payload: { msg: error.response, status: error.response.status },
    });
  }
};

export const addCategory = ( title, description , url ,sortBy) => async (dispatch) => {
  
  const config = { headers: { "Content-Type": "application/json" } };
  try {
   
    const res = await axios.post(`/api/category`, {title , description,url ,sortBy} , config);
    dispatch({
      type: ADD_CATEGORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CATEGORY,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateCategory = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(`/api/category/${formData.id}`, formData, config);
    dispatch({
      type: UPDATE_CATEGORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CATEGORY,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    //eslint-disable-next-line
    const res = await axios.delete(`/api/category/${id}`);
    dispatch({
      type: DELETE_CATEGORY,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CATEGORY,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
