import axios from "axios";
import {
  GET_TYPES,
  ADD_TYPE,
  UPDATE_TYPE,
  DELETE_TYPE,
  ERROR_TYPE,
} from "./types";

// GET ALL TYPES
export const getTypes = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/types`);
    dispatch({
      type: GET_TYPES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_TYPE,
      payload: { msg: error.response, status:error.response&& error.response.status },
    });
  }
};

export const addType = ( title, description , url , subCategory ,sortBy) => async (dispatch) => {

  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(`/api/types`,{title, description , url , subCategory ,sortBy} ,config);
    dispatch({
      type: ADD_TYPE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_TYPE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateTypes = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(`/api/types/${formData.id}`, formData, config);
    dispatch({
      type: UPDATE_TYPE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_TYPE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteType = (id) => async (dispatch) => {
  try {
    //eslint-disable-next-line
    const res = await axios.delete(`/api/types/${id}`);
    dispatch({
      type: DELETE_TYPE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_TYPE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
