import React, { Fragment } from "react";
import {Accordion ,Card} from "react-bootstrap"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DeleteSubCategory from "../../components/modals/subCategories/DeleteSubCategory";
import EditSubCategory from "../../components/modals/subCategories/EditSubCategory";
import AddType from "../../components/modals/Types/AddType";
import EditType from "../../components/modals/Types/EditType";
import DeleteType from "../../components/modals/Types/DeleteType";

const AdvsItem = ({ adv, isAuthenticated, user ,categories , types}) => {
  const { _id, title, description, url ,category} = adv;

  return (
    <tr>
      <td className="right-align">{title}</td>
      <td className="right-align">{category.title}</td>
      <td className="right-align">{url}</td>
      <td className="center-align" dangerouslySetInnerHTML={{__html :description }} ></td>
      <td className="right-align">
        <ul>
         {types.map((type , i) => <li>{type.title} - 
         <EditType correntType={type}  />
         <DeleteType id={type._id} />
          </li> )}
        </ul>
      </td>
      <td className="right-align">{isAuthenticated && user  && (
            <Fragment>
              <EditSubCategory correntAdv={adv} categories={categories} />
              <DeleteSubCategory id={_id} />
              <AddType subCategoryId={_id} />
            </Fragment>
          )}</td>
    </tr>

  );
};

AdvsItem.propTypes = {
  adv: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AdvsItem);
