import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { addImages } from "../../../actions/product.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const AddImages = ({ addImages,id, setAlert  }) => {
  const [addModals, setShow] = useState(false);
  

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (file === "" ) {
      setAlert("ارجو  تحميل الصور لهدا المنتج ", "danger");
    } else {
        addImages(file,id);
      setAlert("تم اضافة الصور بنجاح", "success");
      setShow(false);
    }
  };

  return (
    <Fragment>
      <a
        href="#!"
        onClick={handleShow}
      >
        {" "}
        <FontAwesomeIcon icon={faPlus} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إضافة صور </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <label htmlFor="file"> ادخل صور للمنتج </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple />
            
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  addImages,
  setAlert, 
})(AddImages);
