import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  GET_ADMINS,USER_DELETE
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`/api/auth`);

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = ({ name, email, password, rolls }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ name, email, password, rolls });

  try {
    const res = await axios.post(`/api/users`, body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    console.log(res.data);

    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(`/api/auth`, body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    console.log(err);
    const errors = err.response;

    if (errors) {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  }
};
// Get User
export const getAdmins = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/auth/admins");

    dispatch({
      type: GET_ADMINS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// delete Account
export const deleteAccount = (_id)=> async dispatch =>{
 
  let res
  try {
     await axios.delete(`/api/auth/${_id}`)
    dispatch({
      type : USER_DELETE,
      payload:_id
    })
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
    
  }
}

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
