import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getProducts } from "../../actions/product.action";
import { getSubCategories } from "../../actions/subCategory.action";
import { getTypes } from "../../actions/Types.action";
import AddProduct from "../../components/modals/products/AddProducts";
import ProductItems from "./ProductItems";
import Spinner from "../../components/Layout/Spinner";
const NewsPage = ({
  getProducts,
  getTypes,
  getSubCategories,
  products: { products, loading },
  subCategories : {subCategories},
  types : {types},
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getProducts();
    getSubCategories()
    getTypes()
    // eslint-disable-next-line
  }, []);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        <div className="card text-center">
          <h1> المنتجات</h1>
        </div>
        {isAuthenticated && user && user.rolls === 2 && (
          <div className="card">
            <AddProduct subCategories={subCategories} types={types} />
          </div>
        )}
        {products &&
          products.map((product) => (
            <Fragment key={product._id}>
              <ProductItems product={product} subCategories={subCategories} />
            </Fragment>
          ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  types: state.types,
  subCategories: state.subCategories,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getProducts,getSubCategories,getTypes })(NewsPage);
