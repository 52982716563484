import axios from "axios";
import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  ERROR_PRODUCT,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";
// GET PRODUCTS
export const getProducts = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/products`);
    dispatch({
      type: GET_PRODUCTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

export const addProducts = (file, title, description , subCategory,type) => async (dispatch) => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("productImage", file[i]);
  }
  formData.append("title", title);
  formData.append("description", description);
  formData.append("subCategory", subCategory);
  formData.append("type", type);
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 5000);
        }
      },
    };
    const res = await axios.post(`/api/products`, formData, options);
    dispatch({
      type: ADD_PRODUCT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
export const addImages = (file, id) => async (dispatch) => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("productImage", file[i]);
  }
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 5000);
        }
      },
    };
    const res = await axios.put(`/api/products/images/${id}`, formData, options);
    dispatch({
      type: UPDATE_PRODUCT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteImages = ( id) => async (dispatch) => {
  
  try {

    const res = await axios.delete(`/api/products/images/${id}`);
    dispatch({
      type: UPDATE_PRODUCT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};



export const addProductsWithoutImage = ( title, description , subCategory,type) => async (dispatch) => {
 
  try {
  
    const res = await axios.post(`/api/products/without`, {title, description , subCategory,type});
    dispatch({
      type: ADD_PRODUCT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateProduct = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(`/api/products/${formData.id}`, formData, config);
    dispatch({
      type: UPDATE_PRODUCT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/products/${id}`);
    dispatch({
      type: DELETE_PRODUCT,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
