import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";

import NavBar from "./components/Layout/NavBar";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Register";
import CategoryPage from "./pages/Category/category.page";
import SubCategoryPage from "./pages/SubCategory/subCategory.page";
import Products from "./pages/Products/product.page";
import AdminsPage from "./pages/Admins/AdminsPage";
import Alert from "./components/Layout/Alert";
import Progress from "./components/Layout/Progress";
import PrivateRoute from "./components/routing/PrivateRoute";
import SuperPrivateRoute from "./components/routing/SuperPrivateRoute";

import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Router>
       <NavBar />
        <div className="container">
          <Alert />
          <Progress />
        </div>
        <Switch>
          <PrivateRoute path="/" exact component={CategoryPage} />   
          <PrivateRoute path="/subcategory" exact component={SubCategoryPage} /> 
          <PrivateRoute path="/products" exact component={Products} />   
          <PrivateRoute path="/admins" exact component={AdminsPage} />   
          <Route exact path="/login" component={Login} />
          <SuperPrivateRoute exact path="/signup" component={Signup} /> 
        </Switch>
    </Router>
  );
}

export default App;
