import axios from "axios";
import {
  GET_SUBCATEGORIES,
  ADD_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  ERROR_SUBCATEGORY,
  
} from "./types";
import {Url} from "./config"

// GET CONFERANCES
export const getSubCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/subcategory`);
    dispatch({
      type: GET_SUBCATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SUBCATEGORY,
      payload: { msg: error.response, status: error.response.status },
    });
  }
};

export const addSubCategory = ( title, description , url , category ,sortBy) => async (dispatch) => {

  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(`/api/subcategory`,{title, description , url , category ,sortBy} ,config);
    dispatch({
      type: ADD_SUBCATEGORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBCATEGORY,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateSubCategory = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(`/api/subcategory/${formData.id}`, formData, config);
    dispatch({
      type: UPDATE_SUBCATEGORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBCATEGORY,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteSubCategory = (id) => async (dispatch) => {
  try {
    //eslint-disable-next-line
    const res = await axios.delete(`/api/subcategory/${id}`);
    dispatch({
      type: DELETE_SUBCATEGORY,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBCATEGORY,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
