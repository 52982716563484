import { combineReducers } from "redux";
import auth from "./auth";
import categories from "./category.reduser";
import subCategories from "./subCategory.reduser";
import types from "./types.reducer";
import products from "./product.reduser";
import alert from "./alert";
import progress from "./Progress";

export default combineReducers({
  auth,
  categories,
  products,
  subCategories,
  alert,
  types,
  progress,
});
