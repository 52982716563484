import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import { getCategory } from "../../actions/category.action";
import AddCategory from "../../components/modals/Categories/AddCategory";
import SubCategoryItems from "./CategoryItems";

import Spinner from "../../components/Layout/Spinner";
const CategoryPage = ({
  getCategory,
  categories: { categories, loading },
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        <div className="card text-center">
          <h1> الاقسام</h1>
        </div>
        {isAuthenticated && user  && (
          <div className="card">
            <AddCategory />
          </div>
        )}
        
        <div className="card text-center">
        <table className="striped container-fluid ">
            <thead>
              <tr>
                <th className="right-align">عنوان القسم</th>
                <th className="right-align"> رمز القسم</th>
                <th className="right-align">عن القسم </th>
                <th className="right-align"> الانواع </th>
              <th></th>
              </tr>
            </thead>
            <tbody>
            {categories.map((category) => (
              <Fragment key={category._id}>
                 <SubCategoryItems adv={category} />
              </Fragment>
             ))}
            </tbody>
          </table>
          </div>
          </div>
     
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getCategory })(CategoryPage);
