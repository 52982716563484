import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({ user, logout, isAuthenticated }) => {
  return (
    <Fragment>
      <div className="navbar bg-dark">
        <Link to="/" className="brand-logo">
          لوحة التحكم
        </Link>
        <ul>
          {isAuthenticated && user && (
            <Fragment>
              <li>
                <Link
                  to="/"
                  className="dropdown-trigger dropdown-navbar"
                  href="#!"
                  data-target="dropdown1"
                >
                   الاقسام
                </Link>
              </li>

              <li>
                <Link
                  to="/subcategory"
                  className="dropdown-trigger dropdown-navbar"
                  href="#!"
                  data-target="dropdown1"
                >
                  الاقسام الفرعية
                </Link>
              </li>
              <li>
                <Link
                  to="/products"
                  className="dropdown-trigger dropdown-navbar"
                  href="#!"
                  data-target="dropdown1"
                >
                   المنتجات
                </Link>
              </li>
              <li>
                <Link
                  to="/admins"
                  className="dropdown-trigger dropdown-navbar"
                  href="#!"
                  data-target="dropdown1"
                >
                   المسؤلين
                </Link>
              </li>

              {user.rolls === 2 && (
                <Fragment>
                  <li>
                    <Link
                      to="/signup"
                      className="dropdown-trigger dropdown-navbar"
                      href="#!"
                      data-target="dropdown1"
                    >
                      إضافة مسؤول
                    </Link>
                  </li>
                </Fragment>
              )}
              <li>
                <a
                  className="dropdown-trigger dropdown-navbar"
                  href="#!"
                  data-target="dropdown1"
                >
                  {user.name}
                </a>
              </li>
              <li>
                <a
                  style={{ paddingLeft: "50px" }}
                  href="#!"
                  onClick={() => logout()}
                >
                  <FontAwesomeIcon icon={faRunning} /> تسجيل خروج
                </a>
              </li>
            </Fragment>
          )}
        </ul>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(NavBar);
